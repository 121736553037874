<template>
  <div class="h-screen flex justify-center items-center">
    <div class="text-center">
      <div class="not-found">
        <div class="font-light not-found-404">404</div>
        <div class="text-xl font-light">KHÔNG TÌM THẤY TRANG</div>
      </div>
      <router-link :to="{ name: 'main' }">
        <button
          class="
            inline-block
            px-5
            py-2
            cursor-pointer
            text-center
            border
            rounded-full
            bg-primary
            mt-5
            text-white
          "
        >
          Quay lại trang chủ
        </button>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.not-found {
  color: #444444;
  text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc,
    1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee,
    4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc,
    4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee,
    7px 6px 1px #cccccc;
}
button {
  z-index: 1;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  color: white;
  padding: 0.5em 1em;
  outline: none;
  border: none;
  background-color: #409eff;
  overflow: hidden;
  cursor: pointer;
}

button::after {
  content: "";
  z-index: -1;
  background-color: #5d5bff33;
  position: absolute;
  top: -50%;
  bottom: -50%;
  width: 1.5em;
  transform: translate3d(-740%, 0, 0) rotate(35deg);
}

button:hover::after {
  transition: transform 0.6s ease-in-out;
  transform: translate3d(200%, 0, 0) rotate(35deg);
}
.not-found-404 {
  font-size: 150px;
  font-weight: 500;
  line-height: 140px;
}
</style>
